import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Box,
  Flex,
} from "@chakra-ui/react";
import { For } from "react-haiku";
import DetailsTab from "./DetailsTab";
import NotificationUpdate from "./NotificationUpdate";
import PasswordUpdate from "./PasswordUpdate";
import { useState } from "react";

const Settings = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Flex w="full">
      <VStack alignItems="stretch" spacing={6} w="full" maxW="800px">
        <Heading
          color="#757998"
          fontWeight="600"
          fontSize={{
            base: "xl",
            md: "2xl",
          }}
        >
          Profile
        </Heading>
        <Tabs
          w="full"
          color={"#A7A9BD"}
          fontWeight={600}
          size="sm"
          fontSize={10}
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab fontSize={{ base: "xs", sm: "sm", md: "md" }}>
              Personal Details
            </Tab>
            <Tab fontSize={{ base: "xs", sm: "sm", md: "md" }}> Password</Tab>
            <Tab fontSize={{ base: "xs", sm: "sm", md: "md" }}>
              Notifications
            </Tab>
          </TabList>
          <TabPanels px={{ base: "0", md: 4 }} mt="4">
            <TabPanel>
              <DetailsTab />
            </TabPanel>
            <TabPanel>
              <PasswordUpdate />
            </TabPanel>
            <TabPanel>
              <NotificationUpdate />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Flex>
  );
};

export default Settings;
