import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import Datatable from "../../../components/Datatable";
import { TEXT_COLOR } from "../../../layout/constant/MenuList";
import { IoMdAddCircle } from "react-icons/io";
import { useGetClientBusiness } from "../../../hooks/clients/useGetClientBusiness";
import BusinessAction from "./BusinessActions";
import CampaignModal from "../../../layout/components/CampaignModal";
import instance from "../../../helpers/axios";

function BusinessDetails({ id }) {
  const [business, setBusiness] = useState([]);
  const { data, refetch } = useGetClientBusiness(id);
  const [open, setOpen] = useState(false);
  const toast = useToast();

  function handleOpenBusinessModal() {
    setOpen(true);
  }

  function handleCloseBusinessModal() {
    setOpen(false);
  }

  const onSubmit = async (values) => {
    await instance({
      method: "POST",
      url: `client/${id}/business/new`,
      data: {
        name: values.name,
        contactName: values.contactName,
        phone: values.phone,
        email: values.email,
        industry: values.industry,
        address: values.address,
      },
    })
      .then((response) => {
        handleCloseBusinessModal();
        toast({
          isClosable: true,
          status: "success",
          variant: "top-accent",
          position: "top-right",
          title: "Success",
          description: response.data?.data?.message,
        });
        refetch();
      })
      .catch((error) => {
        toast({
          isClosable: true,
          status: "error",
          variant: "top-accent",
          position: "top-right",
          description: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setBusiness(data?.businesses);
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Business Name",
        accessor: "name",
      },
      {
        Header: "Contact Name",
        accessor: "contactName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Industry",
        accessor: "industry",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: () => <Text>Actions</Text>,
        accessor: "actions",
        Cell: (data) => {
          return <BusinessAction row={data.row.original} />;
        },
      },
    ],
    []
  );

  return (
    <div>
      <HStack align="end" justify="space-between" mb="4">
        <Heading
          color={TEXT_COLOR}
          fontWeight="500"
          size={{ base: "md", lg: "lg" }}
        >
          Businesses
        </Heading>
        <Button
          colorScheme="blue"
          backgroundColor="#3B82F6"
          borderRadius={4}
          size={{
            base: "sm",
            md: "md",
          }}
          rightIcon={<IoMdAddCircle />}
          onClick={handleOpenBusinessModal}
        >
          Add Business
        </Button>
      </HStack>

      <Divider />
      <Datatable
        data={business || []}
        columns={columns}
        headerColor={"#3B82F6"}
      />
      <CampaignModal
        onClose={handleCloseBusinessModal}
        reportData={{
          name: "",
          contactName: "",
          email: "",
          phone: "",
          industry: "",
          address: "",
        }}
        buttonType={"Add"}
        onSubmit={onSubmit}
        dialogTitle={"Add Another Business"}
        isOpen={open}
      />
      {/* <CreateUserModal isOpen={isOpen} onClose={onClose} /> */}
    </div>
  );
}

export default BusinessDetails;
