import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import "@fontsource/poppins/latin.css";
import "@fontsource/lora/latin.css";
import Routes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};

const Root = () => {
  const theme = extendTheme({
    components: {
      Steps,
      Button: {
        defaultProps: {
          colorScheme: "blue",
        },
      },
      Checkbox: {
        defaultProps: {
          colorScheme: "blue",
        },
      },
      Form: {
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
                {
                  ...activeLabelStyles,
                },
              label: {
                top: "10px",
                left: 0,
                zIndex: 2,
                position: "absolute",
                backgroundColor: "white",
                pointerEvents: "none",
                mx: 3,
                px: 1,
                my: 2,
                transformOrigin: "left top",
              },
            },
          },
        },
      },

      FormLabel: {
        baseStyle: {
          color: "#363849",
          fontSize: "sm",
        },
      },
    },
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    fonts: {
      body: '"Poppins", sans-serif',
      heading: '"Lora", serif',
    },
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: Infinity,
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Routes />
          </Provider>
        </QueryClientProvider>
      </RecoilRoot>
    </ChakraProvider>
  );
};

export default Root;
