import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useToast,
  VStack,
  FormControl,
  FormLabel,
  extendTheme,
  ChakraProvider,
  Input,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik, Field } from "formik";
import CheckboxControl, { InputControl, SubmitButton } from "formik-chakra-ui";
import { useRecoilState } from "recoil";
import { profile } from "../../atoms/authAtom";
import Loading from "../../components/Loading";
import "../Login/floatingStyle.css";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AuthLayout from "../../layout/AuthLayout";

const forgotPasswordInitialValue = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email().required().label("Email address"),
});

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    return await axios({
      method: "POST",
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: "/forgot-password",
      data: {
        email: values.email,
      },
      withCredentials: false,
    })
      .then((response) => {
        if (+response.status === 200) {
          toast({
            isClosable: true,
            status: "success",
            variant: "top-accent",
            position: "top-right",
            title: "Success",
            description: "Reset Password link successfully send to your Email",
          });
          setTimeout(() => (window.location = "/login"), 500);
        }
      })
      .catch((error) => {
        toast({
          isClosable: true,
          status: "error",
          variant: "top-accent",
          position: "top-right",
          description: error.response?.data?.message || "Something went wrong!",
        });
      });
  };

  return isLoading ? (
    <Loading />
  ) : (
    <AuthLayout>
      <VStack alignItems="start">
        <Image
          src={`${process.env.PUBLIC_URL}/images/Kedet Logo.png`}
          height="80px"
        />
        <Text color="#79A9FF" style={{ marginTop: "-16px" }} fontWeight="600">
          Powered by War Room.
        </Text>
        <Stack spacing={4} py={12} w={"full"} maxW={"md"}>
          <Heading
            fontWeight="700"
            fontFamily="Lora"
            color={"#757998"}
            fontSize={35}
          >
            Forgot Password.
          </Heading>
          <Text
            color="#A7A9BD"
            style={{ marginTop: "2px" }}
            fontWeight="600"
            fontFamily={"poppins"}
            fontSize={"14px"}
          >
            Please enter your email address for sending the reset password
            verification link for your kedet account.
          </Text>
          <Formik
            initialValues={forgotPasswordInitialValue}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, dirty, errors, touched, isSubmitting }) => {
              return (
                <VStack as={Form} w="full" alignItems="start">
                  <Stack marginTop={4} w="full" spacing={3} autoComplete="off">
                    <FormControl variant="floating" isRequired isInvalid my={2}>
                      <Box className="login-controller">
                        <Field
                          as={Input}
                          name="email"
                          placeholder=" "
                          className="login-input"
                        />
                        <FormLabel htmlFor="email" className="login-label">
                          Email Address
                        </FormLabel>
                      </Box>
                      {touched.email && (
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems="center"
                    justifyContent={"space-between"}
                    spacing={3}
                  >
                    <Box
                      display={"flex"}
                      alignItems="center"
                      gap="2"
                      opacity={0}
                    >
                      {/*<Checkbox name="remember_me" id="remember_me" />*/}
                      {/*<FormLabel htmlFor="remember_me" margin="0" cursor="pointer" className="login-label">Remember Me</FormLabel>*/}
                    </Box>
                    <Box display={"flex"} alignItems="center" gap="2">
                      <FormLabel marginTop="-8px">
                        <Link to="/login">Back to Login</Link>
                      </FormLabel>
                    </Box>
                  </Box>
                  <SubmitButton
                    variant="solid"
                    colorScheme="blue"
                    borderRadius={0}
                    backgroundColor={"#79A9FF"}
                    style={{ marginTop: "30px", width: "100%" }}
                    px="14"
                  >
                    Submit
                  </SubmitButton>
                </VStack>
              );
            }}
          </Formik>
        </Stack>
      </VStack>
    </AuthLayout>
  );
};

export default ForgotPassword;
