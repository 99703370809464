import { FormControl, FormLabel } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";

const InputBox = ({ name, label, type, ...rest }) => {
  return (
    <FormControl>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputControl
        id={name}
        name={name}
        inputProps={{
          variant: "outline",
          border: "2px",
          borderRadius: 5,
          borderColor: "gray",
          type: type || "text",
          fontSize: "sm",

          ...rest,
        }}
      />
    </FormControl>
  );
};

export default InputBox;
