import React, { useEffect, useState } from "react";
import { Flex, Heading } from "@chakra-ui/layout";
import "./adCampaign.css";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Image,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import AdvancedSettingModal from "../../layout/components/AdvancedSettingModal";
import { useDispatch, useSelector } from "react-redux";
import { settingDemographics } from "../../store/adCampaign";
import { useGetCampaignIndustries } from "../../hooks/config-management/useGetCampaignIndustries";
import ErrorModal from "../../components/PopupModal/ErrorModal";
import { useGetIndustriesCategories } from "../../hooks/config-management/useGetIndustryCategories";
import CampaignGeography from "../../components/AdCampaign/CampaignGeography";

function Demographics({ nextStep, prevStep, handleNotes }) {
  const dispatch = useDispatch();

  const { demographics, businessDetails } = useSelector(
    (state) => state.adCampaign
  );

  const [open, setOpen] = useState(false);
  const [selectedGeography, setSelectedGeography] = useState(
    demographics?.geography || null
  );
  const [location, setLocation] = useState({
    country: demographics?.location?.country || "",
    province: demographics?.location?.province || [],
    city: demographics?.location?.city || [],
    zipCode: demographics?.location?.zipCode || "",
  });

  const [industries, setIndustries] = useState([]);
  const { data } = useGetCampaignIndustries();
  const { data: subCategories } = useGetIndustriesCategories();

  useEffect(() => {
    setIndustries(data?.data);
    setSubCategoryType(subCategories?.data);
  }, [data, subCategories]);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState("");
  const [subCategoryType, setSubCategoryType] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState(
    demographics.audience || ""
  );
  const [isError, setIsError] = useState(false);

  function handleOpenDialog() {
    setOpen(true);
  }

  function handleCloseDialog() {
    setOpen(false);
  }

  function handleSelectedGeography(e) {
    setSelectedGeography(e.target.value);
  }

  function onSubmit() {
    dispatch(
      settingDemographics({
        audience: selectedAudience,
        geography: selectedGeography,
        location: { ...location },
      })
    );
    nextStep();
  }

  function handleAudience(value) {
    setSelectedAudience(value);
  }

  const activeTab = {
    border: "2px solid #3B82F6",
    boxSizing: "border-box",
  };

  function handleCloseErrorModal() {
    setIsErrorModalOpen(false);
    setErrorSubmit("");
  }

  function handleIndustry() {
    setIsErrorModalOpen(true);
    setErrorSubmit("Please contact Account Manager to select another industry");
  }

  const { country, province, city, zipCode } = location;
  useEffect(() => {
    let hasError = false;

    if (selectedGeography === "Country" && !country) {
      hasError = true;
    } else if (selectedGeography === "Province" && (!country || !province)) {
      hasError = true;
    } else if (
      selectedGeography === "City" &&
      (!country || !province || !city)
    ) {
      hasError = true;
    } else if (
      selectedGeography === "Zip Code" &&
      (!country || !province || !city || !zipCode)
    ) {
      hasError = true;
    }

    setIsError(hasError);
  }, [selectedGeography, location]);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading
          mt={5}
          fontSize={{ base: "md", md: "3xl" }}
          fontFamily="poppins"
        >
          Demographics
        </Heading>
        <Button
          variant="none"
          fontFamily="poppins"
          color="#1F95FF"
          p={0}
          mt={5}
          onClick={() => handleOpenDialog()}
        >
          Advanced Settings
        </Button>
      </Flex>
      <Box fontFamily={"poppins"}>
        <Box minHeight={460}>
          <Flex direction="column" mt={10} gap={8}>
            <FormControl maxWidth={440} isRequired>
              <Flex gap={2}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/industry.png`}
                  height="18px"
                />
                <FormLabel>Industry</FormLabel>
              </Flex>
              <Select
                id="industry"
                name="industry"
                variant="outline"
                border="2px"
                fontWeight={500}
                fontSize={"md"}
                borderRadius={1}
                borderColor="#E7E7E9"
                key={industries?.id}
                value={businessDetails.industry}
                onChange={(e) => handleIndustry(true)}
              >
                {!!industries &&
                  industries.map((objective) => (
                    <option
                      className="campaign-option"
                      key={objective?.id}
                      value={objective.industry}
                    >
                      {objective.industry}
                    </option>
                  ))}
              </Select>
            </FormControl>
            {!!subCategoryType?.length && (
              <>
                <Heading fontSize={"lg"} fontFamily="poppins">
                  Auto Recommended Audience
                </Heading>
                <SimpleGrid
                  columns={[1, 1, 2, 3, 4]}
                  alignItems={"flex-start"}
                  spacing="20px"
                >
                  {subCategoryType.map((item, index) => (
                    <Box
                      border={"2px solid rgba(167, 169, 189, 0.2)"}
                      borderRadius={"10px"}
                      minHeight="76px"
                      display={"flex"}
                      flexDirection={"column"}
                      padding={4}
                      cursor={"pointer"}
                      gap={3}
                      key={item?.id}
                      style={
                        selectedAudience === item.subCategory ? activeTab : null
                      }
                      onClick={() => handleAudience(item.subCategory)}
                    >
                      <Flex gap={4} alignItems={"center"} position={"relative"}>
                        <Box
                          padding={2}
                          backgroundColor={"#d5d5d5"}
                          borderRadius={8}
                        >
                          <Image
                            src={`${
                              process.env.PUBLIC_URL
                            }/images/${businessDetails.industry
                              .toLowerCase()
                              .replace("-", "")}.png`}
                            height="30px"
                          />
                        </Box>
                        <Heading
                          fontSize={"md"}
                          fontWeight={500}
                          fontFamily={"poppins"}
                          color={"#1d1d1d"}
                        >
                          {item.subCategory}
                        </Heading>
                      </Flex>
                    </Box>
                  ))}
                </SimpleGrid>
              </>
            )}
            <CampaignGeography
              selectedGeography={selectedGeography}
              handleSelectedGeography={handleSelectedGeography}
              setLocation={setLocation}
              location={location}
            />
          </Flex>
        </Box>
        <Divider mt={8} />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2rem 0 7rem 0",
          }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Button
            fontWeight={400}
            fontFamily={"poppins"}
            leftIcon={
              <Image
                src={`${process.env.PUBLIC_URL}/images/notes.svg`}
                height="18px"
              />
            }
            onClick={handleNotes}
          >
            Add Notes
          </Button>
          <Box
            style={{ display: "flex", justifyContent: "flex-end" }}
            mt={{ base: "6", md: "0" }}
          >
            <Button
              mr={4}
              flex="1"
              _hover={{ bg: "" }}
              color={"#1F95FF"}
              bgColor={"#FFFFFF"}
              border={"2px"}
              borderColor="#1F95FF"
              py={5}
              px={8}
              onClick={() => prevStep()}
            >
              Back
            </Button>
            <Button
              flex="1"
              _hover={{ bg: "" }}
              color={"#FFFFFF"}
              bgColor={"#1F95FF"}
              border={"2px"}
              borderColor="#1F95FF"
              py={5}
              px={8}
              isDisabled={isError || !selectedGeography}
              onClick={onSubmit}
              type="submit"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
      <AdvancedSettingModal isOpen={open} onClose={handleCloseDialog} />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => handleCloseErrorModal()}
        description={errorSubmit}
      />
    </>
  );
}

export default Demographics;
